<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="uid" label="用户编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="user_nickname" label="用户昵称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="user_phone" label="手机号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="time_start" label="首次成为代理时间" show-overflow-tooltip>
        <template v-slot="s">{{s.row.time_start | date}}</template>
      </el-table-column>
      <el-table-column prop="time_end" label="有效期截止" show-overflow-tooltip>
        <template v-slot="s">{{s.row.time_end | date}}</template>
      </el-table-column>
      <el-table-column prop="status" :formatter="formatStatus" label="状态" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" show-overflow-tooltip>
        <template #header>
          <y_choose_member @change="chooseNew">
            <el-button >添加代理</el-button>
          </y_choose_member>
        </template>
        <template v-slot="s">
          <el-button @click="remove(s.row)" type="danger">取消资格</el-button>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </el-card>
</template>

<script>

import y_choose_member from "@/components/user/YUserChoose";

export default {
  components:{y_choose_member},
  name: "manage",
  data(){
    return{
      list:[],
      page:1,
      total:0,
      editBox:false,
      editForm:{

      }
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    formatStatus(e){
      let str = "";
      switch (parseInt(e.status)){
        case 1:
          str = "有效"
          break;
        case 2:
          str = "已过期";
          break;
      }
      return str;
    },
    remove({id}){
      this.$u.api.card.plugin.agent.del({id}).then(()=>{
        this.$message.success("操作成功")
        this.load();
      })
    },
    chooseNew({id}){
      this.$u.api.card.plugin.agent.handsApply({id}).then(()=>{
        this.$message.success("操作成功")
        this.load();
      })
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.$u.api.card.plugin.agent.search({page:this.page}).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>